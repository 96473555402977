define("discourse/plugins/discourse-jira/discourse/components/fields/dj-multiselect-field", ["exports", "@ember/component", "@ember/object", "discourse/plugins/discourse-jira/discourse/components/fields/dj-base-field", "@ember/template-factory"], function (_exports, _component, _object, _djBaseField, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="field control-group">
    {{fields/dj-field-label label=this.label field=this.field}}
  
    <div class="controls">
      <MultiSelect
        @content={{this.replacedContent}}
        @value={{this.field.value}}
        @onChange={{action (mut this.field.value)}}
      />
    </div>
  </div>
  */
  {
    "id": "C+qUIkCg",
    "block": "[[[10,0],[14,0,\"field control-group\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"label\",\"field\"],[[30,0,[\"label\"]],[30,0,[\"field\"]]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"replacedContent\"]],[30,0,[\"field\",\"value\"]],[28,[37,3],[[30,0],[28,[37,4],[[30,0,[\"field\",\"value\"]]],null]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"fields/dj-field-label\",\"multi-select\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-jira/discourse/components/fields/dj-multiselect-field.hbs",
    "isStrictMode": false
  });
  class MultiselectField extends _djBaseField.default {
    get replacedContent() {
      return (this.field.options || []).map(o => {
        return {
          id: o.id,
          name: o.value
        };
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "replacedContent", [(0, _object.computed)("field.options.[]")]))();
  }
  _exports.default = MultiselectField;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MultiselectField);
});