define("discourse/plugins/discourse-jira/discourse/components/fields/dj-dropdown-field", ["exports", "@ember/component", "@ember/object", "discourse/plugins/discourse-jira/discourse/components/fields/dj-base-field", "@ember/template-factory"], function (_exports, _component, _object, _djBaseField, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="field control-group">
    {{fields/dj-field-label label=this.label field=this.field}}
  
    <div class="controls">
      {{combo-box
        value=this.field.value
        content=this.replacedContent
        onChange=(action (mut this.field.value))
        options=(hash allowAny=false disabled=this.field.isDisabled)
      }}
    </div>
  </div>
  */
  {
    "id": "RzREx1KJ",
    "block": "[[[10,0],[14,0,\"field control-group\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"label\",\"field\"],[[30,0,[\"label\"]],[30,0,[\"field\"]]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"value\",\"content\",\"onChange\",\"options\"],[[30,0,[\"field\",\"value\"]],[30,0,[\"replacedContent\"]],[28,[37,3],[[30,0],[28,[37,4],[[30,0,[\"field\",\"value\"]]],null]],null],[28,[37,5],null,[[\"allowAny\",\"disabled\"],[false,[30,0,[\"field\",\"isDisabled\"]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"fields/dj-field-label\",\"combo-box\",\"action\",\"mut\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-jira/discourse/components/fields/dj-dropdown-field.hbs",
    "isStrictMode": false
  });
  class DropdownField extends _djBaseField.default {
    get replacedContent() {
      return (this.field.options || []).map(o => {
        return {
          id: o.id,
          name: o.value
        };
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "replacedContent", [(0, _object.computed)("field.options.[]")]))();
  }
  _exports.default = DropdownField;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropdownField);
});