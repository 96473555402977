define("discourse/plugins/discourse-jira/discourse/initializers/add-discourse-jira-button", ["exports", "discourse/lib/plugin-api", "discourse/widgets/post-cooked", "discourse-common/lib/deprecated", "discourse-common/lib/icon-library", "discourse/plugins/discourse-jira/discourse/components/modal/attach", "discourse/plugins/discourse-jira/discourse/components/modal/create", "discourse/plugins/discourse-jira/discourse/components/post-menu/jira-menu-button"], function (_exports, _pluginApi, _postCooked, _deprecated, _iconLibrary, _attach, _create, _jiraMenuButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-jira",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.discourse_jira_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.34.0", api => {
        customizePostMenu(api);
        api.includePostAttributes("jira_issue");
        api.decorateWidget("post-contents:after-cooked", helper => {
          const postModel = helper.getModel();
          if (!postModel || !postModel.jira_issue) {
            return;
          }
          const jira = postModel.jira_issue;
          const jiraUrl = jira.self.replace(/\/rest\/api\/.*$/, "/browse/" + jira.key);
          const cooked = `
            <aside class='quote jira-issue' data-jira-key='${jira.key}'>
              <div class='title'>
                ${(0, _iconLibrary.iconHTML)("tag")}
                <a href='${jiraUrl}'>${jira.fields.summary}</a>
              </div>
              <blockquote>
                <i>(${jira.key})</i>
                <span class='jira-status jira-status-${jira.fields.status.id}'>
                  ${jira.fields.status.name}
                </span>
              </blockquote>
            </aside>
          `;
          const postCooked = new _postCooked.default({
            cooked
          }, helper);
          return helper.rawHtml(postCooked.init());
        });
      });
    }
  };
  function customizePostMenu(api) {
    const currentUser = api.getCurrentUser();
    const transformerRegistered = api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          firstButtonKey
        }
      } = _ref;
      if (!currentUser?.can_create_jira_issue) {
        return;
      }
      dag.add("jira", _jiraMenuButton.default, {
        before: firstButtonKey
      });
    });
    const silencedKey = transformerRegistered && "discourse.post-menu-widget-overrides";
    (0, _deprecated.withSilencedDeprecations)(silencedKey, () => customizeWidgetPostMenu(api));
  }
  function customizeWidgetPostMenu(api) {
    const currentUser = api.container.lookup("service:current-user");
    const modal = api.container.lookup("service:modal");
    api.attachWidgetAction("post", "createIssue", function () {
      modal.show(_create.default, {
        model: this.model
      });
    });
    api.attachWidgetAction("post", "attachIssue", function () {
      modal.show(_attach.default, {
        model: this.model
      });
    });
    api.attachWidgetAction("post-menu", "toggleJiraMenu", function () {
      this.state.jiraVisible = !this.state.jiraVisible;
    });
    api.attachWidgetAction("post-menu", "closeJiraMenu", function () {
      this.state.jiraVisible = false;
    });
    api.addPostMenuButton("jira", attrs => {
      if (currentUser?.can_create_jira_issue && !attrs.jira_issue) {
        return {
          action: "toggleJiraMenu",
          icon: "fab-jira",
          className: "jira-menu",
          title: "discourse_jira.menu.title",
          position: "first"
        };
      }
    });
    api.decorateWidget("post-menu:before-extra-controls", helper => {
      if (!helper.state.jiraVisible) {
        return;
      }
      return helper.attach("post-jira-menu");
    });
  }
});